import calendarStaffQuery from 'my-phorest/gql/queries/calendar-staff.graphql';
import { STAFF_CALENDAR_MODE } from 'my-phorest/utils/calendar';
import staffCalendarPositionField from 'my-phorest/gql/fragments/staff-calendar-position.graphql';
import { evictBookingHistory } from 'my-phorest/utils/graphql';

/**
 * This util is specialised in modifying Calendar's cache. Typically, a modification of cache is more straightforward.
 * The complexity comes from keeping "calendarDays" in a resource and shuffling events references.
 *
 * Dictionary:
 *  - Event - Appointment or Break
 *  - Resource - Staff Member, Room or Machine
 */

/**
 * Adds an event to calendar's cache.
 *
 * @param {ApolloCache} cache
 * @param {object} data
 * @param {string} data.date
 * @param {string} data.eventId
 * @param {string} [data.eventType="Appointment"]
 * @param {string} data.resourceId
 * @param {string} [data.resourceType="Staff"]
 * @returns void
 */
export function addEvent(
  cache,
  {
    date,
    eventId,
    eventType = 'Appointment',
    resourceId,
    resourceType = 'Staff',
  }
) {
  const resourceRef = _getRef(cache, resourceId, resourceType);

  cache.modify({
    id: resourceRef,
    fields: {
      calendarDays(existing = []) {
        const day = _findCalendarDayByDate(existing, date);
        const eventRefObj = _createRefObj(cache, eventId, eventType);

        if (!day || _isEventPresent(cache, day, eventId, eventType)) {
          // Event is already present in the cache (no duplicates!)
          return existing;
        }

        return [
          ...existing.slice(0, day.index),
          {
            ...day.item,
            events: [...day.item.events, eventRefObj],
          },
          ...existing.slice(day.index + 1),
        ];
      },
    },
  });

  evictStaffCalendarIfStaffMemberNotWorking(cache, {
    staffId: resourceId,
    startDate: date,
    endDate: date,
    resourceType,
  });
}

/**
 * Updates cache for newly created appointments.
 *
 * @param {ApolloCache} cache - The Apollo cache object
 * @param {array|object} appointment - List of appointments or appointment object
 * @returns void
 */
export function addEventsToCalendarCache(cache, appointment) {
  if (Array.isArray(appointment)) {
    appointment.forEach((appt) => {
      _addEventToCalendarCache(cache, appt);
    });
  } else {
    _addEventToCalendarCache(cache, appointment);
  }

  evictBookingHistory(cache);
  cache.gc();
}

export function updateEvent(
  cache,
  { date, resourceId, resourceType = 'Staff' }
) {
  evictStaffCalendarIfStaffMemberNotWorking(cache, {
    staffId: resourceId,
    startDate: date,
    endDate: date,
    resourceType,
  });
}

function evictStaffCalendarIfStaffMemberNotWorking(
  cache,
  { staffId, startDate, endDate, resourceType }
) {
  if (resourceType === 'Staff') {
    [
      STAFF_CALENDAR_MODE.SINGLE_STAFF,
      STAFF_CALENDAR_MODE.WORKING_STAFF,
      STAFF_CALENDAR_MODE.ALL_STAFF,
    ].forEach((mode) => {
      const response = cache.readQuery({
        query: calendarStaffQuery,
        variables: { startDate, endDate, mode },
      });

      if (
        response &&
        response.staffCalendar &&
        !response.staffCalendar.find((staff) => staff.id === staffId)
      ) {
        // XXX We received an event for a staff member that is not working (rare event).
        // This upsets our staffCalendar cache, so we evict at the penalty of showing a calendar spinner.

        cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'staffCalendar',
        });

        cache.gc();
      }
    });
  }
}

export function updateStaffCalendarPosition(cache) {
  cache.modify({
    id: 'ROOT_QUERY',
    fields: {
      staffCalendar(existing = []) {
        return [...existing].sort((a, b) => {
          let staffMemberA = cache.readFragment({
            id: a.__ref,
            fragment: staffCalendarPositionField,
          });
          let staffMemberB = cache.readFragment({
            id: b.__ref,
            fragment: staffCalendarPositionField,
          });

          return staffMemberA.calendarPosition - staffMemberB.calendarPosition;
        });
      },
    },
  });
}

export function updateGroupBookingPrimaryClient(
  cache,
  appointmentId,
  groupBookingId,
  primaryClientId
) {
  const appointmentsFromPrimaryClientId = Object.values(cache.data.data).filter(
    (obj) =>
      obj.__typename === 'Appointment' &&
      obj.groupBookingId === groupBookingId &&
      obj.id !== appointmentId
  );

  appointmentsFromPrimaryClientId.forEach((appt) => {
    cache.modify({
      id: cache.identify({ id: appt.id, __typename: 'Appointment' }),
      fields: {
        groupBookingPrimaryClientId() {
          return primaryClientId;
        },
      },
      broadcast: false,
    });
  });
}

/**
 * Invalidate all calendar queries / buckets with cache.
 *
 * We also invalidate {Machines|Rooms|StaffMembers}.calendarDays here. Since we are not modifying
 * queries anymore and adjust calendarDays read method, we need to invalid
 * individual resources as well. But we do it in a smart way by invalidating
 * only calendarDays, not the whole resource. Technically a Machine, Room or Staff Member can still
 * be retrieved from cache if a consumer doesn't need calendarDays.
 *
 * @param {ApolloCache} cache
 */
export function invalidate(cache) {
  // Invalidate all calendarDays for all Machines, Rooms and StaffMembers
  const serializedState = cache.extract();
  Object.values(serializedState)
    .filter((item) => ['Machine', 'Room', 'Staff'].includes(item.__typename))
    .forEach((resource) => {
      const ref = _getRef(cache, resource.id, resource.__typename);
      cache.evict({
        id: ref,
        fieldName: 'calendarDays',
      });
    });

  // Invalidate all `staffCalendar` queries (all calendar queries, no matter the filters)
  cache.evict({
    id: 'ROOT_QUERY',
    fieldName: 'machinesCalendar',
  });
  cache.evict({
    id: 'ROOT_QUERY',
    fieldName: 'roomsCalendar',
  });
  cache.evict({
    id: 'ROOT_QUERY',
    fieldName: 'staffCalendar',
  });
  cache.gc();
}

/**
 * Moves an event from one place to another for all resources at once.
 * This is based on oldEvent and newEvent given by the FullCalendar.
 *
 * @param {ApolloCache} cache
 * @param {object} data
 * @param {object} data.oldEvent
 * @param {object} data.newEvent
 * @returns void
 */
export function moveEvents(cache, { oldEvent, newEvent }) {
  const fromDate = oldEvent.date;
  const toDate = newEvent.date;
  const eventId = newEvent.id;
  const eventType = newEvent.__typename;

  const resourceTypes = [
    { name: 'Machine', idField: 'machine.id' },
    { name: 'Room', idField: 'room.id' },
    { name: 'Staff', idField: 'staffMemberId' },
  ];

  resourceTypes.forEach(({ name: resourceType, idField }) => {
    const idFieldParts = idField.split('.');
    let fromResourceId = oldEvent[idField];
    let toResourceId = newEvent[idField];

    if (idFieldParts.length === 2) {
      fromResourceId = oldEvent[idFieldParts[0]]?.[idFieldParts[1]];
      toResourceId = newEvent[idFieldParts[0]]?.[idFieldParts[1]];
    }

    moveEvent(cache, {
      fromDate,
      toDate,
      eventId,
      eventType,
      fromResourceId,
      toResourceId,
      resourceType,
    });
  });
}

/**
 * Moves an event from one place to another. The event can change a Resource and/or a date.
 *
 * @param {ApolloCache} cache
 * @param {object} data
 * @param {string} data.fromDate
 * @param {string} data.toDate
 * @param {string} data.eventId
 * @param {string} [data.eventType="Appointment"]
 * @param {string} data.fromResourceId
 * @param {string} data.toResourceId
 * @param {string} [data.resourceType="Staff"]
 * @returns void
 */
export function moveEvent(
  cache,
  {
    fromDate,
    toDate,
    eventId,
    eventType = 'Appointment',
    fromResourceId,
    toResourceId,
    resourceType = 'Staff',
  }
) {
  removeEvent(cache, {
    date: fromDate,
    resourceId: fromResourceId,
    eventId,
    eventType,
    resourceType,
  });
  addEvent(cache, {
    date: toDate,
    resourceId: toResourceId,
    eventId,
    eventType,
    resourceType,
  });
}

/**
 * Removes an event from calendar's cache.
 *
 * Please note:
 * We typically don't use this method alone. When removing an event we typically just evict a normalized id, like that:
 *
 * ```
 * cache.evict({ id: normalizedId });
 * ```
 *
 * That means we keep a [dangling reference](https://www.apollographql.com/docs/react/caching/garbage-collection/#dangling-references)
 * in place in `calendarDays`. Which doesn't harm us on cache reading, but helps in implementing Undo feature, because
 * we don't need to push it back there through `addEvent` method.
 *
 * @param {ApolloCache} cache
 * @param {object} data
 * @param {string} data.date
 * @param {string} data.eventId
 * @param {string} [data.eventType="Appointment"]
 * @param {string} data.resourceId
 * @param {string} [data.resourceType="Staff"]
 * @returns void
 */
export function removeEvent(
  cache,
  {
    date,
    eventId,
    eventType = 'Appointment',
    resourceId,
    resourceType = 'Staff',
  }
) {
  const resourceRef = _getRef(cache, resourceId, resourceType);

  cache.modify({
    id: resourceRef,
    fields: {
      calendarDays(existing = []) {
        const day = _findCalendarDayByDate(existing, date);
        const eventRefObj = _createRefObj(cache, eventId, eventType);

        if (!day || !_isEventPresent(cache, day, eventId, eventType)) {
          // Day or event not found in cache. No need to remove anything.
          return existing;
        }

        const event = _findEventByRef(day.item.events, eventRefObj.__ref);

        return [
          ...existing.slice(0, day.index),
          {
            ...day.item,
            events: [
              ...day.item.events.slice(0, event.index),
              ...day.item.events.slice(event.index + 1),
            ],
          },
          ...existing.slice(day.index + 1),
        ];
      },
    },
  });
}

/**
 * Clear appointments from all resources calendar days GraphQL cache.
 *
 * @param {ApolloCache} cache - The Apollo cache object
 * @param {array} appointments - List of appointments
 * @returns void
 */
export function evictAppointmentsFromResourcesCalendar(cache, appointments) {
  appointments.forEach((appointment) => {
    const { id: eventId, date } = appointment;

    if (appointment.staffMember) {
      removeEvent(cache, {
        date,
        eventId,
        eventType: 'Appointment',
        resourceId: appointment.staffMember.id,
        resourceType: 'Staff',
      });
    }

    if (appointment.room) {
      removeEvent(cache, {
        date,
        eventId,
        eventType: 'Appointment',
        resourceId: appointment.room.id,
        resourceType: 'Room',
      });
    }

    if (appointment.machine) {
      removeEvent(cache, {
        date,
        eventId,
        eventType: 'Appointment',
        resourceId: appointment.machine.id,
        resourceType: 'Machine',
      });
    }
  });
}

/**
 * Creates an object with just `__ref` property.
 * It's useful to push references into lists.
 *
 * @param {ApolloCache} cache
 * @param {string} id
 * @param {string} type
 * @returns {{__ref}}
 * @private
 */
function _createRefObj(cache, id, type) {
  return { __ref: _getRef(cache, id, type) };
}

/**
 * Returns a calendar day and its index in calendarDays array if it's possible to find it.
 *
 * @param {array} days
 * @param {string} date
 * @returns {null|{item: object, index: number}}
 * @private
 */
function _findCalendarDayByDate(days, date) {
  let index = days.findIndex((i) => i.date === date);
  if (index < 0 || !days[index]) {
    return null;
  }
  return { index, item: days[index] };
}

/**
 * Returns an event and its index in events array if it's possible to find it.
 *
 * @param {array} events
 * @param {string} ref
 * @returns {null|{item: object, index: number}}
 * @private
 */
function _findEventByRef(events, ref) {
  let index = events.findIndex((i) => i.__ref === ref);
  if (index < 0 || !events[index]) {
    return null;
  }
  return { index, item: events[index] };
}

/**
 * Gets reference for a GraphQL resource.
 *
 * @param {ApolloCache} cache
 * @param {string} id
 * @param {string} type
 * @returns {*}
 * @private
 */
function _getRef(cache, id, type) {
  return cache.identify({ id, __typename: type });
}

/**
 * Checks if an event is present in a given day.
 *
 * @param {ApolloCache} cache
 * @param {object} day
 * @param {string} eventId
 * @param {string} eventType
 * @returns {boolean}
 * @private
 */
function _isEventPresent(cache, day, eventId, eventType) {
  const eventRef = _getRef(cache, eventId, eventType);
  return day.item.events.some((event) => event.__ref === eventRef);
}

function _addEventToCalendarCache(cache, appointment) {
  addEvent(cache, {
    date: appointment.date,
    eventId: appointment.id,
    resourceId: appointment.staffMemberId,
  });
  if (appointment.machine?.id) {
    addEvent(cache, {
      date: appointment.date,
      eventId: appointment.id,
      resourceId: appointment.machine.id,
      resourceType: 'Machine',
    });
  }
  if (appointment.room?.id) {
    addEvent(cache, {
      date: appointment.date,
      eventId: appointment.id,
      resourceId: appointment.room.id,
      resourceType: 'Room',
    });
  }
}
