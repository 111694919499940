import Controller from '@ember/controller';
import { queryManager } from 'ember-apollo-client';
export default class IntegrationsController extends Controller {
  @queryManager apollo;

  queryParams = [
    'supplier',
    'key',
    'code',
    'state',
    'businessId',
    'branchId',
    'silo',
  ];

  get queryParamsHash() {
    return {
      supplier: this.supplier,
      key: this.key,
      code: this.code,
      state: this.state,
      businessId: this.businessId,
      branchId: this.branchId,
      silo: this.silo,
    };
  }
}
