/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { service } from '@ember/service';

export default class UiInputDiscountWithKeypadComponent extends Component {
  @service keypad;
  @service intl;
  @service currencyFormatter;

  get formattedDiscount() {
    const { discount } = this.args;
    if (!discount) return '';

    if (discount.type === 'PERCENT') {
      return this.intl.formatNumber(discount.value / 100, {
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: 3,
      });
    } else {
      return this.currencyFormatter.format(discount.value);
    }
  }

  @action
  openKeypad() {
    this.keypad.openKeypad({
      value: {
        amount: this.args.discount?.value,
        type: this.args.discount?.type,
      },
      headerText: this.intl.t('global.discount'),
      required: true,
      variant: 'discount',
      hideFixedDiscounts: this.args.hideFixedDiscounts,
      hideApplyToAllSwitch: this.args.hideApplyToAllSwitch,
      onChange: (discount) =>
        this.args.onInput({ type: discount.type, value: discount.amount }),
    });
  }
}
