import Service, { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import { ALL_ISSUES } from 'my-phorest/utils/calendar';
import { isPresent } from '@ember/utils';
import { queryManager } from 'ember-apollo-client';
import updateAppointmentsTime from 'my-phorest/gql/mutations/update-appointments-time.graphql';
import * as calendarCache from 'my-phorest/utils/calendar-cache';

export default class UpdateAppointmentsService extends Service {
  @service appointmentIssues;
  @service fullCalendar;

  @queryManager apollo;

  @dropTask
  *updateAppointmentsTask(data, variables, previousValues) {
    const { newResource, oldResource } = data;

    let { updateAppointments } = yield this.updateCallTask.perform(variables, {
      oldAppointments: previousValues,
      oldResource,
    });
    let { issues } = updateAppointments;
    if (isPresent(issues)) {
      if (
        yield this.appointmentIssues.askForConfirmation({
          issues,
          context: { resource: (newResource ?? oldResource)?.extendedProps },
        })
      ) {
        const acceptedIssues = issues.map((issue) => issue.issueType);
        variables.issues = [...variables.issues, ...acceptedIssues];

        let response = yield this.updateCallTask.perform(variables, {
          oldAppointments: previousValues,
          oldResource,
        });
        updateAppointments = response.updateAppointments;
      }
    }

    if (!updateAppointments.issues || updateAppointments.issues?.length === 0) {
      this.fullCalendar.actionsHistory.addHistoryPoint({
        context: this,
        undo() {
          return this.updateCallTask.perform(
            {
              appointments: previousValues,
              issues: ALL_ISSUES,
            },
            {
              oldAppointments: updateAppointments.appointments,
              oldResource: newResource,
            }
          );
        },
      });
    }

    return updateAppointments;
  }

  @dropTask
  *updateCallTask(variables, previousValues) {
    return yield this.apollo.mutate({
      mutation: updateAppointmentsTime,
      variables,
      update: (cache, { data: { updateAppointments } }) => {
        const { appointments } = updateAppointments;

        appointments?.forEach((appointment, index) => {
          const oldAppointment = previousValues?.oldAppointments[index];

          if (appointment && oldAppointment) {
            if (
              previousValues?.oldResource ||
              oldAppointment.date !== appointment.date
            ) {
              calendarCache.moveEvents(cache, {
                oldEvent: oldAppointment,
                newEvent: appointment,
              });
            }
          }
        });

        cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'serviceHistory',
        });
        cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'clientNextAppointment',
        });
        cache.gc();

        this.fullCalendar.calendarApi.refetchResources();
      },
    });
  }
}
