import Service, { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { queryManager } from 'ember-apollo-client';
import GetVoucherBySerial from 'my-phorest/gql/queries/get-voucher-by-serial.graphql';

export default class VoucherReceiptService extends Service {
  @service session;
  @service router;
  @service('browser/window') window;
  @service electronApp;

  @queryManager apollo;

  @task
  *getVouchersToPrintTask(serials) {
    return yield this.getVouchersBySerialsTask.perform(serials);
  }

  @task
  *printInElectronTask(serials) {
    for (let i = 0; i < serials.length; i++) {
      const path = this.router.urlFor(
        'accounts.account.print.voucher-receipt',
        this.session.currentAccountId,
        {
          queryParams: {
            serial: serials[i],
          },
        }
      );
      const { protocol, host } = this.window.location;
      const printURL = new URL(`${protocol}//${host}${path}`);

      yield this.electronApp.printReceipt(
        printURL.toString(),
        {},
        { waitForDataLoadedAttr: true }
      );
    }
  }

  @task
  *getVouchersBySerialsTask(serials) {
    let promises = serials.map((serial) => {
      return this.getVoucherTask.perform(serial);
    });
    return yield Promise.all(promises);
  }

  @task
  *getVoucherTask(serial) {
    let voucher = yield this.apollo.query(
      {
        query: GetVoucherBySerial,
        variables: {
          serial,
        },
      },
      'voucherBySerial'
    );
    return voucher;
  }
}
