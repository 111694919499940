/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class ModalMarketingConsentComponent extends Component {
  @service marketingConsentModal;
  @service intl;

  @tracked selectedValue = this.marketingOptions[0];

  marketingOptions = [
    {
      value: 'sms-email',
      label: this.intl.t(
        'client.overview-page.marketing-consent-modal.dropdown-options.sms-email'
      ),
    },
    {
      value: 'sms',
      label: this.intl.t(
        'client.overview-page.marketing-consent-modal.dropdown-options.sms'
      ),
    },
    {
      value: 'email',
      label: this.intl.t(
        'client.overview-page.marketing-consent-modal.dropdown-options.email'
      ),
    },
  ];

  buildReturnValue(opts) {
    if (!opts) return null;
    if (this.selectedValue.value === 'sms-email') {
      return {
        marketingEmailOptout: opts.optOut,
        marketingSmsOptout: opts.optOut,
      };
    } else if (this.selectedValue.value === 'sms') {
      return {
        marketingEmailOptout: true,
        marketingSmsOptout: opts.optOut,
      };
    } else if (this.selectedValue.value === 'email') {
      return {
        marketingEmailOptout: opts.optOut,
        marketingSmsOptout: true,
      };
    }
  }

  @action
  onSelect(option) {
    this.selectedValue = option;
  }

  @action
  optIn() {
    this.marketingConsentModal.closeWithValue(
      this.buildReturnValue({ optOut: false })
    );
  }

  @action
  optOut() {
    this.marketingConsentModal.closeWithValue(
      this.buildReturnValue({ optOut: true })
    );
  }

  @action
  cancel() {
    this.marketingConsentModal.closeWithValue(null);
  }

  @action
  optInUsOrCanada() {
    this.marketingConsentModal.closeWithValue({
      marketingEmailOptout: false,
      marketingSmsOptout: false,
    });
  }

  @action
  optOutUsOrCanada() {
    this.marketingConsentModal.closeWithValue({
      marketingEmailOptout: false,
      marketingSmsOptout: true,
    });
  }
}
