import Service, { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { queryManager } from 'ember-apollo-client';
import { variation } from 'ember-launch-darkly';

export default class PurchaseReceiptVoucherService extends Service {
  @service receiptSettings;
  @service voucherReceipt;

  @queryManager apollo;

  @task
  *getVouchersToPrintTask(serials) {
    let shouldPrint = yield this.shouldPrintTask.perform();

    if (shouldPrint) {
      return yield this.voucherReceipt.getVouchersToPrintTask.perform(serials);
    }
    return [];
  }

  @task
  *printInElectronTask(serials) {
    let shouldPrint = yield this.shouldPrintTask.perform();

    if (shouldPrint) {
      yield this.voucherReceipt.printInElectronTask.perform(serials);
    }
  }

  @task
  *shouldPrintTask() {
    if (!variation('release-receipt-voucher-print')) return false;
    let receiptSettings = yield this.receiptSettings.fetchReceiptSettings();
    if (receiptSettings?.printVoucher) {
      return true;
    }
    return false;
  }
}
