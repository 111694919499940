export function shouldNotifySentryAboutCheckoutError(errors) {
  if (!Array.isArray(errors)) return true;

  const [firstError] = errors;
  const ACCEPTED_GENERAL_PAYMENT_ERRORS = [
    'VOUCHER_TOTAL_EXCEEDS_PURCHASE_TOTAL',
  ];

  if (
    firstError?.extensions?.validationCode &&
    ACCEPTED_GENERAL_PAYMENT_ERRORS.includes(
      firstError.extensions.validationCode
    )
  ) {
    return false;
  } else if (
    firstError?.extensions?.validationCode === 'INTEGRATED_PAYMENT_ERROR'
  ) {
    const ACCEPTED_INTEGRATED_PAYMENT_ERRORS = [
      'AUTHENTICATION_REQUIRED',
      'CARD_DECLINED',
      'CARD_EXPIRED',
      'CARD_LIMIT_EXCEEDED',
      'INCORRECT_PIN',
      'INSUFFICIENT_FUNDS',
      'INVALID_NUMBER',
      'LOST_CARD',
      'OTHER',
      'STOLEN_CARD',
      'TERMINAL_BUSY',
      'TERMINAL_OFFLINE',
    ];

    // Params are specific integrated payment errors
    const { params } = firstError.extensions;

    for (let i = 0; i < params.length; i++) {
      if (!ACCEPTED_INTEGRATED_PAYMENT_ERRORS.includes(params[i].value)) {
        return true;
      }
    }

    return false;
  }

  return true;
}
