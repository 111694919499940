/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
/* eslint-disable phorest/moment-import */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import { isEmpty, isPresent } from '@ember/utils';
import { debounce } from '@ember/runloop';
import { isValidFloat } from 'my-phorest/utils/is-valid-float';
import { format as formatTime } from 'my-phorest/utils/local-time-helpers';
import { format as formatDatetime } from 'my-phorest/utils/local-datetime-helpers';
import {
  convertFrom24To12Format,
  convertFrom12To24Format,
} from 'my-phorest/utils/time-conversions';
import decimalSymbolByLocale from 'my-phorest/utils/decimal-symbol-by-locale';
import {
  dropTask,
  keepLatestTask,
  restartableTask,
  task,
  timeout,
} from 'ember-concurrency';
import marketingSettingsQuery from 'my-phorest/gql/queries/marketing-settings.graphql';
import { queryManager } from 'ember-apollo-client';
import moment from 'moment';
import timeoutForEnv from 'my-phorest/utils/timeout-for-env';
import fixedDiscounts from 'my-phorest/gql/queries/fixed-discounts-unpaginated.graphql';
import { DISCOUNT_TYPES } from 'my-phorest/utils/discounts';

const INPUT_DEBOUNCE_MS = 250;

class BaseVariant {
  @tracked value;
  @tracked hasInvalidError = false;

  constructor(value) {
    this.value = value;
  }

  toggleNegativeValue() {} // noop
  toggleTimeRepresentation() {} // noop

  computeValue({ currentValue, newChar, keypadInput, numberOfDecimals = 2 }) {
    let newValue;
    if (keypadInput.selectionStart === keypadInput.selectionEnd) {
      if (isEmpty(currentValue)) currentValue = '';
      newValue = currentValue + newChar;
      let re = new RegExp(`\\.(\\d{1,${numberOfDecimals}}).*$`);
      newValue = newValue.replace(re, '.$1');
      newValue = newValue.replace(/^00/, '0');
    } else {
      let inputArray = keypadInput.value.split('');
      let charsSelected =
        keypadInput.selectionEnd - keypadInput.selectionStart - 1;
      inputArray.splice(keypadInput.selectionStart, charsSelected);
      inputArray[keypadInput.selectionStart] = newChar;
      newValue = inputArray.join('').replace(',', '');
    }

    return newValue;
  }

  get isDisabled() {
    return false;
  }

  @action
  clearValue() {
    this.value = '';
  }

  @action
  formatFinalValue() {} // noop
}

/* BaseVariant class for any keypads that allow for numbers with multiple leading zeros */
export class TextBaseVariant extends BaseVariant {
  computeValue({ currentValue, newChar, keypadInput }) {
    let newValue;
    if (keypadInput.selectionStart === keypadInput.selectionEnd) {
      if (isEmpty(currentValue)) currentValue = '';
      newValue = currentValue + newChar;
    } else {
      let inputArray = keypadInput.value.split('');
      let charsSelected =
        keypadInput.selectionEnd - keypadInput.selectionStart - 1;
      inputArray.splice(keypadInput.selectionStart, charsSelected);
      inputArray[keypadInput.selectionStart] = newChar;
      newValue = inputArray.join('');
    }

    return newValue;
  }
}

export class IntegerVariant extends BaseVariant {
  @tracked validationSchemaError = false;

  constructor(value, { keypad, intl }) {
    super(...arguments);
    this.keypad = keypad;
    this.intl = intl;
  }

  get isOkButtonDisabled() {
    if (!this.keypad.allowNull && !this.value) {
      return true;
    }

    if (this.validationSchemaError) {
      return true;
    }

    if (this.keypad.allowZero) {
      return false;
    }

    return !this.value || Number(this.value) <= 0;
  }

  @action
  onInput(value) {
    this.value = value.replace(/[^0-9]/g, '');
    this.validateSchema.perform(this.value);
  }

  @action
  updateValue(newChar, keypadInput, numberOfDecimals = 2) {
    let newValue = this.computeValue({
      currentValue: this.value,
      newChar: newChar,
      keypadInput: keypadInput,
      numberOfDecimals,
    });
    if (isValidFloat(newValue)) {
      this.value = newValue;
    }
    this.validateSchema.perform(this.value);
  }

  @action
  clearValue() {
    this.value = '';
    this.validationSchemaError = false;
  }

  @action
  formatFinalValue() {
    if (this.keypad.allowNull && !this.value) {
      this.value = null;
      return;
    }

    if (String(this.value).endsWith('.')) {
      this.value = this.value.slice(0, -1);
    }
  }

  @task
  *validateSchema(value) {
    this.validationSchemaError = false;
    if (this.keypad.validationSchema) {
      try {
        yield this.keypad.validationSchema.validate(value);
      } catch (e) {
        this.translateError(e);
      }
    }
  }

  @action
  translateError(error) {
    if (error.message.key) {
      this.validationSchemaError = this.intl.t(error.message.key);
    }
  }
}

export class NumberVariant extends IntegerVariant {
  get isOkButtonDisabled() {
    return false;
  }

  @action
  onInput(value, decimalSymbol) {
    let startsWithDecimalSymbol = new RegExp(`^[${decimalSymbol}]`, 'g');
    value = value.replace(startsWithDecimalSymbol, `0${decimalSymbol}`);

    if (decimalSymbol === ',') {
      value = value.replaceAll('.', '').replace(',', '.');
    }

    this.value = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');

    if (value.split('.')[1]?.length > 2) {
      this.value = value.slice(0, -1);
    }

    this.hasInvalidError = false;
  }
}

export class VoucherVariant extends TextBaseVariant {
  @tracked validVoucher;

  constructor(value, { keypad, session }) {
    super(...arguments);
    this.keypad = keypad;
    this.session = session;
  }

  get disableWandButton() {
    return (
      isPresent(this.value) &&
      this.value === this.generateVoucherSerialTask?.lastSuccessful?.value
    );
  }

  get isExpired() {
    let voucher = this.getVoucherBySerialTask.lastSuccessful?.value;
    if (voucher && this.keypad.voucherWorkflow === 'use') {
      return voucher.expired;
    }
    return false;
  }

  get isDisabled() {
    return (
      this.getVoucherBySerialTask.isRunning ||
      this.generateVoucherSerialTask.isRunning
    );
  }

  @keepLatestTask
  *generateVoucherSerialTask() {
    this.value = this.generateVoucherSerialTask.lastSuccessful?.value;
    if (!isPresent(this.value)) {
      this.value = yield this.keypad.generateVoucherSerialTask.perform();
    }
    this.validVoucher = true;

    return this.value;
  }

  @restartableTask
  *getVoucherBySerialTask(value) {
    this.value = value;

    if (this.value === '') {
      this.validVoucher = true;
      return;
    }
    yield timeout(timeoutForEnv(INPUT_DEBOUNCE_MS));
    try {
      let voucher = yield this.keypad.getVoucherBySerialTask.perform(
        this.value
      );
      this.validVoucher = this.keypad.isValidVoucher(voucher);
      return voucher;
    } catch (e) {
      if (e.message === 'No voucher found') {
        this.validVoucher = this.keypad.isValidVoucher();
      } else {
        throw e;
      }
    }
  }

  @action
  clearValue() {
    this.value = '';
  }

  @action
  async updateValue(newChar, keypadInput) {
    let newValue = this.computeValue({
      currentValue: this.value,
      newChar: newChar,
      keypadInput: keypadInput,
    });
    this.value = newValue;
    this.getVoucherBySerialTask.perform(newValue);
  }

  @action
  formatFinalValue() {
    if (this.keypad.voucherWorkflow === 'use') {
      this.value = this.getVoucherBySerialTask.lastSuccessful.value;
    }
  }
}

export class PriceVariant extends BaseVariant {
  @tracked isNegative = false;
  @tracked validationSchemaError = false;

  constructor(value, { currencyFormatter, keypad, intl }) {
    super(...arguments);
    this.keypad = keypad;
    this.intl = intl;

    if (this.keypad.allowNull && value == null) {
      this.value = null;
      return;
    }

    if (isEmpty(value)) value = '';
    const money = currencyFormatter.ensureIsMoneyObj(value);

    let { amount, currency } = money;
    amount = String(amount);

    if (amount.includes('-') && this.keypad.allowNegative) {
      this.isNegative = true;
      this.value = {
        amount: amount.replace('-', ''),
        currency,
      };
    } else {
      this.value = { amount, currency };
    }
  }

  get isOkButtonDisabled() {
    if (!this.keypad.allowNull && !this.value?.amount) {
      return true;
    }

    // Checks that value is not 0, 0.0, 0.00, 0., or .
    let validValue = this.value?.amount.match(/^(?=.*[1-9])\d+(\.\d+)?$/);

    return !validValue && !this.keypad.allowZero;
  }

  @action
  clearValue() {
    this.validationSchemaError = false;
    this.isNegative = false;
    this.value = this.keypad.allowNull
      ? null
      : {
          amount: '0',
          currency: this.value.currency,
        };
  }

  @action
  onInput(value, decimalSymbol) {
    let startsWithDecimalSymbol = new RegExp(`^[${decimalSymbol}]`, 'g');
    value = value.replace(startsWithDecimalSymbol, `0${decimalSymbol}`);

    if (value.includes('-') && !this.isNegative && this.keypad.allowNegative) {
      this.toggleNegativeValue();
    }

    if (decimalSymbol === ',') {
      value = value.replaceAll('.', '').replace(',', '.');
    }

    value = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');

    if (value.split('.')[1]?.length > 2) {
      value = value.slice(0, -1);
    }

    this.value = {
      amount: value,
      currency: this.value.currency,
    };

    this.validateSchema.perform(this.value);
  }

  @task
  *validateSchema(value) {
    this.validationSchemaError = false;
    if (this.keypad.validationSchema) {
      try {
        yield this.keypad.validationSchema.validate(value);
      } catch (e) {
        this.translateError(e);
      }
    }
  }

  @action
  translateError(error) {
    if (error.message.key) {
      this.validationSchemaError = this.intl.t(error.message.key);
    }
  }

  @action
  toggleNegativeValue() {
    this.isNegative = !this.isNegative;
  }

  @action
  updateValue(newChar, keypadInput) {
    let newValue = this.computeValue({
      currentValue: this.value.amount,
      newChar: newChar,
      keypadInput: keypadInput,
    });

    let startsWithDecimalSymbol = new RegExp(`^[.]`, 'g');
    newValue = newValue.replace(startsWithDecimalSymbol, `0.`);

    if (isValidFloat(newValue)) {
      this.value = {
        amount: newValue,
        currency: this.value.currency,
      };
    }

    this.validateSchema.perform(this.value);
  }

  @action
  formatFinalValue() {
    if (this.keypad.allowNull && !this.value) {
      this.value = null;
      return;
    }
    let amount = this.value.amount.replace(/^00$/g, '0');

    if (this.isNegative && Number(amount) > 0) {
      amount = '-' + amount;
    }

    if (amount.endsWith('.')) {
      amount = amount.slice(0, -1);
    }

    this.value = {
      amount: amount,
      currency: this.value.currency,
    };
  }
}

export class DiscountVariant extends PriceVariant {
  @tracked discountType;
  @tracked value;
  @tracked fixedDiscounts;
  @tracked applyToAll = false;

  constructor(value, { apollo }) {
    super(...arguments);

    this.apollo = apollo;

    this.fetchFixedDiscountsTask.perform();

    this.discountType = value.type ?? DISCOUNT_TYPES.AMOUNT;
    this.value = {
      amount: value.amount ?? '0',
      type: this.discountType,
      id: value.id,
      family: value.family,
    };
  }

  get isFixedDiscountSelected() {
    return this.value.id && this.value.family === DISCOUNT_TYPES.FIXED;
  }

  get hideFixedDiscounts() {
    return this.keypad.hideFixedDiscounts;
  }

  get hideApplyToAllSwitch() {
    return this.keypad.hideApplyToAllSwitch;
  }

  get selectedFixedDiscountId() {
    return this.value.id;
  }

  get isOkButtonDisabled() {
    return false;
  }

  @task
  *fetchFixedDiscountsTask() {
    this.fixedDiscounts = yield this.apollo.query(
      { query: fixedDiscounts },
      'fixedDiscounts'
    );
  }

  @action
  clearValue() {
    super.clearValue();
    this.hasInvalidError = false;
  }

  @action
  onDiscountTypeChange(discountTypeOrId) {
    if (
      discountTypeOrId !== DISCOUNT_TYPES.AMOUNT &&
      discountTypeOrId !== DISCOUNT_TYPES.PERCENT
    ) {
      let fixedDiscount = this.fixedDiscounts.find(
        (d) => d.id === String(discountTypeOrId)
      );

      this.discountType = fixedDiscount.type;
      this.value = {
        amount: fixedDiscount.value,
        type: fixedDiscount.type,
        id: fixedDiscount.id,
        family: DISCOUNT_TYPES.FIXED,
      };
    } else {
      this.discountType = discountTypeOrId;
      this.value = {
        amount: this.value.amount,
        type: discountTypeOrId,
      };
    }
  }

  @action
  onInput(value, decimalSymbol) {
    if (!this.isFixedDiscountSelected) {
      super.onInput(value, decimalSymbol);
      this.hasInvalidError = false;
    }
  }

  @action
  updateValue(newChar, keypadInput) {
    if (!this.isFixedDiscountSelected) {
      super.updateValue(newChar, keypadInput);
      this.hasInvalidError = false;
    }
  }

  @action
  formatFinalValue() {
    let amount = String(this.value.amount).replace(/^00$/g, '0');

    if (amount.endsWith('.')) {
      amount = amount.slice(0, -1);
    }

    if (this.discountType === DISCOUNT_TYPES.PERCENT && amount > 100) {
      this.hasInvalidError = true;
    }

    this.value = {
      amount: amount,
      currency: this.value.currency,
      type: this.discountType,
      id: this.value.id,
      family: this.value.family,
      applyToAll: this.applyToAll,
    };
  }
}

export class PercentVariant extends IntegerVariant {
  get numberOfDecimalPlaces() {
    return this.keypad.decimalPlaces ?? 2;
  }
  get isOkButtonDisabled() {
    return false;
  }

  @action
  onInput(value, decimalSymbol) {
    let startsWithDecimalSymbol = new RegExp(`^[${decimalSymbol}]`, 'g');
    value = value.replace(startsWithDecimalSymbol, `0${decimalSymbol}`);

    if (decimalSymbol === ',') {
      value = value.replaceAll('.', '').replace(',', '.');
    }

    this.value = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');

    if (value.split('.')[1]?.length > this.numberOfDecimalPlaces) {
      this.value = value.slice(0, -1);
    }

    this.hasInvalidError = false;
  }

  @action
  updateValue(newChar, keypadInput) {
    super.updateValue(newChar, keypadInput, this.numberOfDecimalPlaces);
    this.hasInvalidError = false;
  }

  @action
  clearValue() {
    super.clearValue();
    this.hasInvalidError = false;
  }

  @action
  formatFinalValue() {
    let amount = String(this.value);
    if (amount.endsWith('.')) {
      amount = amount.slice(0, -1);
    }

    if (amount > 100) {
      this.hasInvalidError = true;
    }

    this.value = amount;
  }
}

export class PinVariant extends TextBaseVariant {
  @action
  onInput(value) {
    this.value = value.replace(/[^0-9]/g, '');
  }

  @action
  updateValue(newChar, keypadInput) {
    let newValue = this.computeValue({
      currentValue: this.value,
      newChar: newChar,
      keypadInput: keypadInput,
    });

    this.value = newValue;
  }
}

export class TimeVariant extends BaseVariant {
  @tracked timeRepresentation = 'AM';
  @tracked isToggleTimeRepresentationDisabled = false;
  @tracked isTimeEmpty = false;
  @tracked isTimeOutsideSocialHours = false;

  outsideSocialHoursErrorMessage;

  constructor(value, { keypad }) {
    super(...arguments);
    this.keypad = keypad;

    if (isEmpty(value)) return;

    if (value.slice(0, 2) >= 12) {
      this.timeRepresentation = 'PM';
    }
    if (!this.keypad.intl.force24HourClock) {
      this.value = convertFrom24To12Format(value);
    } else {
      this.value = value;
    }
  }

  get hasOutsideSocialHoursError() {
    return (
      !this.keypad.allowTimeOutsideSocialHours && this.isTimeOutsideSocialHours
    );
  }

  get hasRequiredError() {
    return this.keypad.required && this.isTimeEmpty;
  }

  @action
  clearValue() {
    this.value = '';
    this.isTimeOutsideSocialHours = false;
    this.isTimeEmpty = false;
    this.hasInvalidError = false;
    this.isToggleTimeRepresentationDisabled = false;
  }

  @action
  nowTime(session, intl) {
    let fullTime;
    let force24HourClock = this.keypad.intl.force24HourClock;
    if (session?.branchTimeZone) {
      let localTime = this.keypad.branchTime.time;
      fullTime = formatTime(localTime, intl, {
        hour: '2-digit',
        minute: '2-digit',
        hour12: !force24HourClock,
      });
    } else {
      fullTime = formatDatetime(new Date(), intl, {
        hour: '2-digit',
        minute: '2-digit',
        hour12: !force24HourClock,
      });
    }

    fullTime = fullTime.toUpperCase().replace(' ', '');
    let time = force24HourClock ? fullTime : fullTime.slice(0, -2);
    let period = fullTime.slice(-2);
    if (time.slice(0, 2) === '00' && period === 'PM') {
      time = time.replace(/^00/, '12');
    }
    this.value = time;
    this.timeRepresentation = period;
    this.isToggleTimeRepresentationDisabled = false;
    this.hasInvalidError = false;
    this.isTimeOutsideSocialHours = false;
    this.isTimeEmpty = false;
  }

  @action
  onInput(value) {
    this.hasInvalidError = false;
    this.isTimeOutsideSocialHours = false;
    this.isTimeEmpty = false;
    this.isToggleTimeRepresentationDisabled = false;
    this.value = this.formatTime(value);
  }

  @action
  updateValue(newChar, keypadInput) {
    if (
      this.hasInvalidError ||
      this.isTimeOutsideSocialHours ||
      this.isTimeEmpty
    )
      this.clearValue();

    let newValue = this.computeValue({
      currentValue: this.value,
      newChar: newChar,
      keypadInput: keypadInput,
    });
    this.value = this.formatTime(newValue);
  }

  formatTime(time) {
    let newTime = time.replace(/[^0-9]/g, '');
    let timeArray = newTime.slice(0, 4).split('');
    let formattedTime = '';
    switch (timeArray.length) {
      case 1:
        formattedTime = timeArray[0];
        break;
      case 2:
        formattedTime = timeArray[0] + timeArray[1];
        break;
      case 3:
        formattedTime = timeArray[0] + ':' + timeArray[1] + timeArray[2];
        break;

      case 4:
        formattedTime =
          timeArray[0] + timeArray[1] + ':' + timeArray[2] + timeArray[3];
        break;

      default:
        formattedTime = '';
        break;
    }
    let hour = formattedTime.substring(0, 2);
    if (hour > 12) {
      this.timeRepresentation = 'PM';
      this.isToggleTimeRepresentationDisabled = true;
    } else if (hour == '00') {
      this.timeRepresentation = 'AM';
      this.isToggleTimeRepresentationDisabled = true;
    } else {
      this.isToggleTimeRepresentationDisabled = false;
    }

    return formattedTime;
  }

  @action
  formatFinalValue(socialHours, intl) {
    if (!isEmpty(this.value)) {
      this.isTimeEmpty = false;
      if (this.value.length === 1) {
        this.value = '0' + this.value + ':00';
      } else if (this.value.length === 2) {
        this.value += ':00';
      } else if (this.value.length === 4) {
        this.value = '0' + this.value;
      }

      let [hours, minutes] = this.value.split(':');
      if (hours > 23 || minutes > 59) {
        this.hasInvalidError = true;
      }

      if (!this.keypad.allowTimeOutsideSocialHours) {
        this.checkForOutsideSocialHours(socialHours, intl);
      }
      if (
        !this.hasInvalidError &&
        !this.hasOutsideSocialHoursError &&
        !this.keypad.intl.force24HourClock
      ) {
        this.value = convertFrom12To24Format(
          this.value + ' ' + this.timeRepresentation
        );
      }
    } else {
      this.isTimeEmpty = true;
    }
  }

  @action
  toggleTimeRepresentation() {
    if (!this.isToggleTimeRepresentationDisabled) {
      this.timeRepresentation = this.timeRepresentation === 'AM' ? 'PM' : 'AM';
      this.isTimeOutsideSocialHours = false;
    }
  }

  @action
  async checkForOutsideSocialHours(socialHours, intl) {
    const { fromTime, toTime } = socialHours;

    const selectedTime = moment(
      convertFrom12To24Format(this.value + ' ' + this.timeRepresentation),
      'HH:mm'
    );
    const minTime = moment(fromTime, 'HH:mm:ss.ms');
    const maxTime = moment(toTime, 'HH:mm:ss.ms');

    let isOutsideOfSideSocialHours =
      (selectedTime.isSameOrAfter(minTime) &&
        !selectedTime.isSameOrBefore(maxTime)) ||
      (!selectedTime.isSameOrAfter(minTime) &&
        selectedTime.isSameOrBefore(maxTime));

    if (isOutsideOfSideSocialHours) {
      this.isTimeOutsideSocialHours = true;
      this.outsideSocialHoursErrorMessage = intl.t(
        'validations.outside-social-hours',
        {
          fromTime: minTime.format('h:mma'),
          toTime: maxTime.format('h:mma'),
        }
      );
    }
  }
}

export default class KeypadComponent extends Component {
  @service keypad;
  @service intl;
  @service('browser/document') document;
  @service session;
  @tracked keypadInput;
  @tracked socialHours;

  @queryManager apollo;

  constructor() {
    super(...arguments);

    if (!this.keypad.allowTimeOutsideSocialHours) {
      this.fetchSocialHoursTask.perform();
    }
  }

  @dropTask
  *fetchSocialHoursTask() {
    let marketingSettings = yield this.apollo.query(
      { query: marketingSettingsQuery },
      'business.marketing'
    );

    this.socialHours = marketingSettings?.sendingHours;
  }

  get variant() {
    return this.keypad.variantInstance;
  }

  get decimalSymbol() {
    return decimalSymbolByLocale(this.intl);
  }

  get hasInvalidError() {
    return this.variant.hasInvalidError;
  }

  get hasOutsideSocialHoursError() {
    return this.variant.hasOutsideSocialHoursError;
  }

  get hasRequiredError() {
    return this.variant.hasRequiredError;
  }

  get hasError() {
    return (
      this.hasInvalidError ||
      this.hasOutsideSocialHoursError ||
      this.hasRequiredError ||
      !!this.variant.validationSchemaError
    );
  }

  get isDisabled() {
    return this.variant.isDisabled;
  }

  get outsideSocialHoursErrorMessage() {
    return this.variant.outsideSocialHoursErrorMessage;
  }

  get generateVoucherSerialTask() {
    return this.variant.generateVoucherSerialTask;
  }

  get disableWandButton() {
    return this.variant.disableWandButton;
  }

  get discountType() {
    return this.variant.discountType;
  }

  get getVoucherBySerialTask() {
    return this.variant.getVoucherBySerialTask;
  }

  get isFixedDiscountSelected() {
    return this.variant.isFixedDiscountSelected;
  }

  get selectedFixedDiscountId() {
    return this.variant.selectedFixedDiscountId;
  }
  get isOkButtonDisabled() {
    return this.variant.isOkButtonDisabled;
  }

  get isValidVoucher() {
    return this.variant.validVoucher;
  }

  get value() {
    return this.variant.value;
  }

  get isNegative() {
    return this.variant.isNegative;
  }

  get timeRepresentation() {
    return this.variant.timeRepresentation;
  }

  get isToggleTimeRepresentationDisabled() {
    return this.variant.isToggleTimeRepresentationDisabled;
  }

  @action
  clearValue() {
    this.variant.clearValue();
    this.variant.hasInvalidError = false;
    this.variant.isTimeOutsideSocialHours = false;
    this.variant.isTimeEmpty = false;
  }

  @action
  handleContextMenu(e) {
    e.preventDefault();
  }

  @action
  nowTime() {
    this.variant.nowTime(this.session, this.intl);
  }

  @action
  toggleNegativeValue() {
    this.variant.toggleNegativeValue();
  }

  @action
  toggleTimeRepresentation() {
    this.variant.toggleTimeRepresentation();
  }

  @action
  onCancel() {
    if (typeof this.keypad.onCancel === 'function') {
      this.keypad.onCancel();
      return;
    }

    this.keypad.closeKeypad();
  }

  @action
  onDiscountTypeChange(discount) {
    this.variant.onDiscountTypeChange(discount);
  }

  @action
  onInput(value) {
    this.variant.onInput(value, this.decimalSymbol);
  }

  @action
  registerKeypadInput(el) {
    this.keypadInput = el;
  }

  @action
  updateValue(value) {
    this.variant.updateValue(value, this.keypadInput, this.decimalSymbol);
  }

  @dropTask
  *onSubmitTask(event) {
    event?.preventDefault();

    if (typeof this.keypad.onChange !== 'function') return;
    if (this.isOkButtonDisabled) return;
    this.variant.formatFinalValue(this.socialHours, this.intl);
    if (this.variant.hasInvalidError) return;
    const success = yield this.keypad.onChange(this.variant.value);

    if (typeof success === 'boolean' && !success) {
      this.variant.hasInvalidError = true;
      debounce(this, this.clearValue, 850);
    } else {
      this.clearValue();

      if (this.keypad.autoClose) {
        this.keypad.closeKeypad();
      }
    }
  }

  @action
  sendValue() {
    this.variant.formatFinalValue(this.socialHours, this.intl);

    if (!this.hasError) {
      if (this.keypad.autoClose) {
        this.keypad.closeKeypad();
      }
      this.keypad.onChange(this.variant.value);
    }
  }
}
