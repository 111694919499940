/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import * as yup from 'yup';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task, keepLatestTask } from 'ember-concurrency';
import { isPresent } from '@ember/utils';

import { queryManager } from 'ember-apollo-client';
import generateLoyaltyCardSerial from 'my-phorest/gql/queries/get-client-treat-card-unique-serial.graphql';
import updateClient from 'my-phorest/gql/mutations/update-client.graphql';

const NON_UNIQUE_LOYALTY_CARD_NUM = 'NON_UNIQUE_LOYALTY_CARD_NUM';

export default class ModalCreateTreatCardComponent extends Component {
  @service createTreatCardModal;
  @service scanTreatCardModal;

  @tracked serial;

  @queryManager apollo;

  get validationSchema() {
    return yup.object().shape({
      serial: yup.string().required(),
    });
  }

  get clientId() {
    return this.args.client?.id;
  }

  get shouldDisableGenerator() {
    return this.generateTreatCardNumberTask.isRunning;
  }

  @action
  cleanUp(form) {
    this.serial = '';
    form.clearValidationMessages();
  }

  @action
  skip() {
    this.createTreatCardModal.close();
    this.createTreatCardModal.addClientIdToClientsAsked(this.clientId);
  }

  @action
  async onSubmit(_event, revalidate) {
    try {
      await this.apollo.mutate(
        {
          mutation: updateClient,
          variables: {
            clientId: this.clientId,
            input: {
              treatCard: {
                serial: this.serial,
              },
            },
          },
        },
        'updateClient'
      );
    } catch (e) {
      if (e.errors?.length > 0) {
        if (
          e.errors[0].extensions?.validationCode == NON_UNIQUE_LOYALTY_CARD_NUM
        ) {
          const validationSchema = this.validationSchema;

          let schema = yup.object().shape({
            serial: validationSchema.fields.serial.test(
              'duplicate-treatcard-serial',
              e.errors[0].message,
              () => false
            ),
          });

          revalidate(schema);
          return;
        }
      }
      throw e;
    }
    this.scanTreatCardModal.addClientIdToPresentedCards(this.clientId);
    this.createTreatCardModal.close();
  }

  @action
  async generateTreatCardNumber() {
    const serial = await this.generateTreatCardNumberTask.perform();
    if (isPresent(serial)) {
      this.updateTreatCardNumber(serial);
    }
  }

  @task
  *barcodeScanTask(form, barcode) {
    this.serial = barcode;
    yield form.validate();
  }

  @keepLatestTask
  *generateTreatCardNumberTask() {
    let response = yield this.apollo.query(
      {
        query: generateLoyaltyCardSerial,
        variables: {
          clientId: this.clientId,
        },
        fetchPolicy: 'network-only',
      },
      'generateLoyaltyCardSerial'
    );

    return response.serial;
  }

  @action
  updateTreatCardNumber(serial) {
    this.serial = serial;
  }
}
