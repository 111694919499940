import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task, waitForProperty } from 'ember-concurrency';
import { action } from '@ember/object';
import { queryManager } from 'ember-apollo-client';
import ClientMarketingConsent from 'my-phorest/gql/queries/client-marketing-consent.graphql';
import UpdateClientMarketingConsent from 'my-phorest/gql/mutations/update-client-marketing-consent.graphql';
import { variation } from 'ember-launch-darkly';

export default class MarketingConsentModalService extends Service {
  @service session;
  @service errorHandler;

  @queryManager apollo;

  @tracked isOpenModalEuropeanSalon = false;
  @tracked isOpenModalUsOrCanadaSalon = false;
  @tracked chosenValue = null;

  @task
  *askIfAllowed(client) {
    if (this.#shouldOpenModal(client)) {
      if (this.isUsOrCanadaSalon) {
        this.isOpenModalUsOrCanadaSalon = true;
        yield waitForProperty(this, 'isOpenModalUsOrCanadaSalon', false);
      } else {
        this.isOpenModalEuropeanSalon = true;
        yield waitForProperty(this, 'isOpenModalEuropeanSalon', false);
      }
      return this.chosenValue;
    }
  }

  @task
  *loadClientMarketingConsentDetailsTask(clientId) {
    return yield this.apollo.query(
      {
        query: ClientMarketingConsent,
        variables: { id: clientId },
      },
      'client'
    );
  }

  @task
  *saveClientMarketingConsentDetails(preferences, clientId) {
    if (preferences) {
      try {
        yield this.apollo.mutate(
          {
            mutation: UpdateClientMarketingConsent,
            variables: {
              clientId,
              input: {
                ...preferences,
                marketingOptinAnswered: true,
              },
            },
          },
          'client'
        );
      } catch (e) {
        this.errorHandler.handle(e, { showError: false });
      }
    }
  }

  @action
  close() {
    this.isOpenModalUsOrCanadaSalon = false;
    this.isOpenModalEuropeanSalon = false;
  }

  get isAustralianSalon() {
    return this.session.locale.countryCode === 'AU';
  }

  get isUsOrCanadaSalon() {
    let countryCode = this.session.locale.countryCode;
    return countryCode === 'US' || countryCode === 'CA';
  }

  @action
  closeWithValue(value) {
    this.chosenValue = value;
    this.close();
  }

  #notAsked(marketingOptinAnswered) {
    if (!variation('release-show-marketing-consent-on-checkout')) {
      return true;
    }
    return !marketingOptinAnswered;
  }

  #shouldOpenModal({
    marketingEmailOptout,
    marketingSmsOptout,
    marketingOptinAnswered,
  }) {
    if (
      this.#notAsked(marketingOptinAnswered) &&
      marketingEmailOptout &&
      marketingSmsOptout
    ) {
      if (this.isAustralianSalon) {
        return false;
      } else if (this.isUsOrCanadaSalon) {
        return true;
      } else {
        return true;
      }
    }
  }
}
