import Service, { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { queryManager } from 'ember-apollo-client';
import { dropTask } from 'ember-concurrency';
import setBasketCheckoutWillDeleteCashup from 'my-phorest/gql/mutations/set-basket-checkout-will-delete-cashup.graphql';

export default class DeleteCashupModalService extends Service {
  @service confirmDialog;
  @service intl;
  @service session;

  @queryManager apollo;

  needsCashupRemoval(basket) {
    return (
      basket.isStrictPOSEnabledAndTillSessionCounted &&
      !basket.willDeleteCashUpOnCheckout
    );
  }

  @dropTask
  *confirmAndDeleteCashupTask(basket) {
    const confirmed = yield this.confirmDialog.ask({
      title: this.intl.t('purchase.basket.delete-cashup-modal.title'),
      message: this.intl.t('purchase.basket.delete-cashup-modal.description'),
      continue: this.intl.t('global.continue'),
      cancel: this.intl.t('global.cancel'),
      leadingIcon: 'question-mark-circle',
    });

    if (confirmed) {
      return this.deleteCashupTask.perform(basket.id);
    } else {
      return false;
    }
  }

  @task
  *deleteCashupTask(basketId) {
    return yield this.apollo.mutate(
      {
        mutation: setBasketCheckoutWillDeleteCashup,
        variables: {
          basketId,
          willDeleteCashUp: true,
        },
      },
      'setBasketWillDeleteCashUpOnCheckout'
    );
  }
}
